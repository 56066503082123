const FacebookWidget = () => {
  return (
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frizerssoftltd&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=415258789593585"
      width="340"
      height="130"
      className="overflow-hidden border-0"
      allowFullScreen={true}
      allow="web-share"
      title="Rizerssoft Ltd"
      loading="lazy"
    ></iframe>
  );
};

export default FacebookWidget;
