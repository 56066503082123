/* eslint-disable import/no-extraneous-dependencies */
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

const hubspotForms = {
  portalId: '139627505',
  formId: 'c3ea6e07-a354-491a-884e-c1f82ce1875c',
};

const ContactForm = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const hubspotRequest = {
          submittedAt: new Date().getTime(),
          fields: [
            {
              objectTypeId: '0-1',
              name: 'full_name',
              value: formData.get('full_name'),
            },
            {
              objectTypeId: '0-1',
              name: 'phone',
              value: formData.get('phone'),
            },
            {
              objectTypeId: '0-1',
              name: 'email',
              value: formData.get('email'),
            },
            {
              objectTypeId: '0-1',
              name: 'message',
              value: formData.get('message'),
            },
          ],
          context: {
            pageName: document.title,
            pageUri: window.location.href,
          },
        };

        const larkBaseRequest = {
          full_name: formData.get('full_name'),
          phone: formData.get('phone'),
          email: formData.get('email'),
          message: formData.get('message'),
        };

        setLoading(true);

        const [hubspotResponse, larkBaseResponse] = await Promise.all([
          fetch(
            `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotForms.portalId}/${hubspotForms.formId}`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(hubspotRequest),
            }
          ),
          fetch('https://crm-flifs4dnfq-uc.a.run.app/contacts/submit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(larkBaseRequest),
          }),
        ]);

        if (hubspotResponse.status === 200 && larkBaseResponse.status === 200) {
          router.reload();
        }
      }}
    >
      <div className="relative mt-4" data-te-input-wrapper-init>
        <label className="text-gradient mb-2 inline-block font-semibold">
          {`${t('contact.form.fullname')}`}
        </label>
        <input
          type="text"
          className="w-full rounded-md border border-[#e0e0e0] bg-white px-6 py-3 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          name="full_name"
          placeholder={`${t('contact.form.fullname')}`}
          aria-label={`${t('contact.form.fullname')}`}
          required
        />
      </div>
      <div className="relative mt-4" data-te-input-wrapper-init>
        <label className="text-gradient mb-2 inline-block font-semibold">
          {`${t('contact.form.phoneNumber')}`}
        </label>
        <input
          type="text"
          className="w-full rounded-md border border-[#e0e0e0] bg-white px-6 py-3 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          name="phone"
          placeholder={`${t('contact.form.phoneNumber')}`}
          aria-label={`${t('contact.form.phoneNumber')}`}
          required
        />
      </div>
      <div className="relative mt-4" data-te-input-wrapper-init>
        <label className="text-gradient mb-2 inline-block font-semibold">
          {`${t('contact.form.email')}`}
        </label>
        <input
          type="email"
          className="w-full rounded-md border border-[#e0e0e0] bg-white px-6 py-3 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          name="email"
          placeholder={`${t('contact.form.email')}`}
          aria-label={`${t('contact.form.email')}`}
        />
      </div>
      <div className="relative mt-4" data-te-input-wrapper-init>
        <label className="text-gradient mb-2 inline-block font-semibold">
          {`${t('contact.form.message')}`}
        </label>
        <textarea
          className="w-full rounded-md border border-[#e0e0e0] bg-white px-6 py-3 text-base font-medium text-[#6B7280] outline-none focus:border-primary focus:shadow-md"
          name="message"
          placeholder={`${t('contact.form.message')}`}
          aria-label={`${t('contact.form.message')}`}
          rows={5}
          required
        />
      </div>
      <button
        type="submit"
        disabled={loading}
        className="bg-gradient mt-4 flex w-full items-center justify-center rounded border-b border-primary p-3 text-lg font-semibold text-white hover:brightness-125 disabled:border-gray-600 disabled:bg-gray-600"
      >
        {t('contact.form.send')}
      </button>
    </form>
  );
};

export default ContactForm;
