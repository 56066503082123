import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faGlobe,
  faLocationDot,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import type { PropsWithChildren } from 'react';
import React from 'react';

import ContactForm from '../ContactForm';
import FacebookWidget from '../socials/FacebookWidget';

const ContactInfo = ({
  title,
  value,
  icon,
}: {
  title: string;
  value: string;
  icon: IconProp;
}) => {
  return (
    <div className="mb-4 flex gap-4">
      <div className="bg-gradient relative flex aspect-square h-12 w-12 items-center justify-center rounded-xl text-2xl text-white">
        <FontAwesomeIcon icon={icon} className="w-5 text-2xl text-white" />
      </div>
      <div className="flex grow flex-col items-start justify-start">
        <span className="text-gradient text-lg font-semibold">{title}</span>
        <span className="text-gray-700">{value}</span>
      </div>
    </div>
  );
};

export default function ContactSection2({ children }: PropsWithChildren) {
  const { t } = useTranslation('common');
  return (
    <section className="relative bg-white">
      <div className="relative mx-auto py-10 sm:container xl:max-w-screen-xl">
        <motion.div
          className="flex w-full flex-col rounded bg-white md:flex-row"
          variants={{
            hidden: { y: 40, opacity: 0 },
            visible: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.7,
              },
            },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {children || (
            <div className="flex w-full flex-col gap-4 p-5">
              <div className="mb-5">
                <span className="text-gradient text-xl font-bold">
                  {t('contact.information')}
                </span>
              </div>
              <ul>
                <li>
                  <ContactInfo
                    icon={faLocationDot}
                    title={t('contact.address.title')}
                    value={t('contact.address.value')}
                  />
                </li>
                <li>
                  <ContactInfo
                    icon={faPhone}
                    title={t('contact.hotline.title')}
                    value={t('contact.hotline.value')}
                  />
                </li>
                <li>
                  <ContactInfo
                    icon={faEnvelope}
                    title={t('contact.email.title')}
                    value={t('contact.email.value')}
                  />
                </li>
                <li>
                  <ContactInfo
                    icon={faGlobe}
                    title={t('contact.website.title')}
                    value={t('contact.website.value')}
                  />
                </li>
              </ul>
              <div>
                <FacebookWidget />
              </div>
            </div>
          )}
          <div className="w-full rounded-lg">
            <div id="contact" className="scroll-margin-top w-full">
              <div className="relative rounded-lg p-5">
                <div className="text-gray-700">{t('contact.subtitle')}</div>
                <div className="text-gradient mb-5 inline-block text-xl font-bold">
                  {t('contact.title')}
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
